@import url('https://fonts.googleapis.com/css2?family=Karla&display=swap');

* {
    box-sizing: border-box;
}

body {
    font-family: "Karla", sans-serif;
    margin: 0;
    background: grey;
}

.container {
    width: 70%;
    margin: 0 auto;
}

.header {
    display: flex;
    align-items: center;
    height: 65px;
    background: linear-gradient(90deg, #672280 1.18%, #A626D3 100%);
    color: white;
    padding: 20px;
}

.header--image {
    height: 100%;
    margin-right: 6px;
}

.header--title {
    font-size: 1.25rem;
    margin-right: auto;
}

.header--project {
    font-size: 0.75rem;
    font-weight: 500;
}

main {
    padding: 36px;
    background: wheat;
}

.form {
    display: grid;
    grid-template: 40px 40px/ 1fr 1fr;
    gap: 17px;
}

.input {
    font-family: "Karla", sans-serif;
    text-indent: 5px;
    border: 1px solid #D5D4D8;
}

.button {
    grid-column: 1 / -1;
    font-family: "Karla", sans-serif;
    border-radius: 5px;
    background: linear-gradient(90.41deg, #711F8D 1.14%, #A818DA 100%);
    color: white;
    cursor: pointer;
    border: none;
}

.meme {
    position: relative;
}

.meme--image{
    width: 100%;
    border-radius: 3px;
}

.meme--text {
    position: absolute;
    width: 80%;
    text-align: center;
    left: 50%;
    transform: translateX(-50%);
    margin: 15px 0;
    padding: 0 5px;
    font-family: impact, sans-serif;
    font-size: 2em;
    color: white;
    letter-spacing: 1px;
    text-transform: uppercase;
    text-shadow:
        2px 2px 0 #000,
        -2px -2px 0 #000,
        2px -2px 0 #000,
        -2px 2px 0 #000,
        0 2px 0 #000,
        2px 0 0 #000,
        0 -2px 0 #000,
        -2px 0 0 #000,
        2px 2px 5px #000;
}

.bottom {
    bottom: 0;
}

.top {
    top: 0;
}

